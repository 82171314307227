<template>
  <div class="main has-header has-footer">
    <div class="logo-area">
      <img v-if="!isXyk" class="logo" alt="logo" src="@assets/xyk_logo.png" />
      <img v-else class="logo" alt="logo" src="@assets/ql_logo.png" />
      <h1 class="txt-black">共享经济综合服务平台</h1>
    </div>

    <!-- 正常授权成功 -->
    <div class="state_box" v-if="isState ==='success'">
      <div class="animate-container">
        <span class="sa-line sa-tip animateSuccessTip"></span>
        <span class="sa-line sa-long animateSuccessLong"></span>
        <div class="animate-before">
          <div class="circle"></div>
        </div>
      </div>
      <p class="state_text">授权成功</p>
      <p class="state_btn" @click="triggerReturn">返回</p>
    </div>
    <!-- 授权失败-接口请求失败 -->
    <div class="state_box" v-if="isState ==='error'">
      <img src="@assets/images/error.png" alt="icon" class="icon_png" />
      <p class="state_text">授权失败</p>
      <p class="state_hint">请刷新页面并检查网络</p>
      <p class="state_btn" @click="triggerReturn">返回</p>
    </div>
    <!-- url参数出现问题 -->
    <div class="state_box" v-if="isState ==='urlDeletion'">
      <img src="@assets/images/cuowu.png" alt="icon" class="icon_png" />
      <p class="state_text">参数错误</p>
      <p class="state_hint">请检查参数</p>
      <p class="state_btn" @click="triggerReturn">返回</p>
    </div>
  </div>
</template>

<script>
import { getParams } from "@utils/common";
import { accessCode } from "@/api/user";
import { MessageBox, Toast, Indicator } from "mint-ui";
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      isState: "",
      isXyk: window.location.origin.indexOf('h5.kylincloud-saas') > -1
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 传递code和openid
    triggerTransmit() {
      const urlData = getParams(window.location.href);
      let data = {
        code: urlData.code,
        userId: urlData.userId || ""
      };
      accessCode(data).then(res => {
        Indicator.close();
        if (res.code === 200) {
          this.isState = "success";
        } else {
          this.isState = "error";
        }
      });
    },
    triggerReturn() {
      const urlData = getParams(window.location.href);
      if (urlData.redirectUrl) {
        window.location.href = urlData.redirectUrl;
      } else {
        Toast("请关闭当前页面");
      }
    }
  },
  mounted() {
    const urlData = getParams(window.location.href);
    console.log(urlData);
    if (urlData.code) {
      Indicator.open("授权中...");
      this.triggerTransmit();
    } else {
      this.isState = "urlDeletion";
    }
  }
};
</script>

<style lang='less' scoped>
.main_box,
.state_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.icon-hot-tip {
  width: 200px;
  height: 150px;
  margin-top: 115px;
}
.state_text {
  padding: 0;
  margin: 30px 0;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
}
.last_text {
  margin: 0;
  padding-top: 10px;
}
.state_btn {
  width: 335px;
  height: 40px;
  background: #c92e26;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
}

.logo-area {
  text-align: center;
  position: relative;
  padding-top: 38px;
  margin-bottom: 20px;
  .logo {
    width: 10rem;
    margin: auto;
  }
  h1 {
    font-size: 1.35rem;
    position: absolute;
    bottom: 60px;
    text-align: center;
    display: block;
    width: 100%;
    font-family: PingFangSC-Semibold, sans-serif;
  }
}

.animate-container {
  width: 100px;
  height: 100px;
  border: 5px solid rgb(0, 255, 0);
  border-radius: 50%;
  margin: 50px auto 20px;
  position: relative;
}
.animate-container .sa-line {
  height: 5px;
  background-color: rgb(0, 255, 0);
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 101;
}
.animate-container .sa-tip {
  width: 25px;
  left: 18px;
  top: 47px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  animation: animationSuccessTip 0.75s;
  -webkit-animation: animationSuccessTip 0.75s;
}
.animate-container .sa-long {
  width: 47px;
  right: 12px;
  top: 40px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  animation: animationSuccessLong 0.75s;
  -webkit-animation: animationSuccessLong 0.75s;
}
.animate-container .animate-before {
  position: absolute;
  overflow: hidden;
  z-index: 100;
  left: -10px;
  top: 10px;
  width: 60px;
  height: 120px;
  background: rgba(255, 255, 255, 1);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.animate-container .animate-before .circle {
  position: absolute;
  left: 16px;
  top: 10px;
  width: 100px;
  height: 100px;
  border: 5px solid rgba(0, 255, 0, 0.5);
  border-radius: 50%;
}
.animate-container:after {
  content: "";
  position: absolute;
  z-index: 99;
  left: 41px;
  top: -11px;
  width: 60px;
  height: 120px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transform-origin: 0px 60px;
  animation: rotateSelf 0.75s;
  -webkit-animation: rotateSelf 0.75s;
}
@keyframes rotateSelf {
  from {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  to {
    -webkit-transform: rotate(-405deg);
    -moz-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@-webkit-keyframes rotateSelf /*Safari and Chrome*/ {
  from {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  to {
    -webkit-transform: rotate(-405deg);
    -moz-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@keyframes animationSuccessLong {
  0% {
    width: 0;
    right: 30px;
    top: 40px;
  }
  65% {
    width: 0;
    right: 45px;
    top: 42px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 37px;
  }
  100% {
    width: 47px;
    right: 12px;
    top: 40px;
  }
}
@-webkit-keyframes animationSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 56px;
  }
  65% {
    width: 0;
    right: 53px;
    top: 56px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 37px;
  }
  100% {
    width: 47px;
    right: 12px;
    top: 40px;
  }
}
@keyframes animationSuccessTip {
  0% {
    width: 0;
    left: 8px;
    top: 8px;
  }
  54% {
    width: 0;
    left: 8px;
    top: 8px;
  }
  70% {
    width: 50px;
    left: 3px;
    top: 30px;
  }
  84% {
    width: 17px;
    left: 30px;
    top: 47px;
  }
  100% {
    width: 25px;
    left: 18px;
    top: 47px;
  }
}
@-webkit-keyframes animationSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 21px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 21px;
  }
  70% {
    width: 50px;
    left: -2px;
    top: 38px;
  }
  84% {
    width: 17px;
    left: 30px;
    top: 47px;
  }
  100% {
    width: 25px;
    left: 18px;
    top: 47px;
  }
}
.icon_png {
  width: 80px;
}
.state_hint {
  padding: 0;
  margin: 0;
  font-size: 12px;
  margin-bottom: 10px;
}
</style>